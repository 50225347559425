// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesAgentShared } from '../../../../../libs/env/src/index';

export const env: EnvironmentSalesAgentShared = {
  static: {
    isAgent: true,
    baseExp: 'Agent',
    applicationName: 'ecp-ui-sales-agent',
    authKey: 'c978ed8f-c811-48ff-96b7-5e1a4b55b0d1',
  },
  chatEnabled: false,
  hotjarTrackingId: '3238847',
  callRecordingServiceUrl: 'ws://localhost:8282/app/rwp',
  datadogService: 'edsp-moonshot',
  datadogApplicationId: 'b34e9af6-4170-4a89-8bec-a0c19d7518c2',
  datadogClientToken: 'pub87ff05b644ef1096cb11e4aa2bf2543a',
};
