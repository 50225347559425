// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesAgentShared } from '../../../../../../libs/env/src/index';

export const env: EnvironmentSalesAgentShared = {
  static: {
    expId: '1600',
    // TODO Remove once TODO for afe-partner-id header in libs/features/sales/shared/utils/network/src/requestWithToken.ts in addressed
    partnerId: '1600',
    title: 'Homesite Insurance',
  },
  afiAppname: 'homesite-agent',
  afiApiKey: 'd81fbb30-12f1-429f-9a75-a6ecb76fffa8',
  sapiClientCredentials: {
    // Amfam Exclusive HSCC
    '1237': {
      sapiClientId: 'rN6LSc6AlYVWRSOYdEjFCufrfKiLKWA8',
      sapiClientSecret: '8GYvZDWlSeizetcDk7B4e_Ehzzqw6k2ZiYiUKtmnSDUnkd8IWNQnA9zyJo_sAh27',
    },
    // Zillow
    '1263': {
      sapiClientId: 'hHvhxnEL4G8RVqrswuqHcc98W3ip3tCD',
      sapiClientSecret: 'Cy_gUEda2aC4bmZkpn9atqAC5UYmqCaF1odBVYEO2I-gXsoOksL4AiRwNF-U3OWZ',
    },
    // GMI
    '7148': {},
    // Homesite SAPI HSCC
    '1198': {},
    // CoverMyStuff HSCC
    '1228': {},
  },
  eligibleProducts: {
    // Zillow
    '1263': ['homesite.home', 'homesite.renters'],
    // GMI
    '7148': ['homesite.home'],
    // Homesite SAPI HSCC
    '1198': ['homesite.home', 'homesite.renters'],
    // CoverMyStuff HSCC
    '1228': ['homesite.renters'],
  },
  pageFlowFlag: {
    '1263': { skipRentersPropertyDetailsPage: true },
  },
};
