import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesAgentShared } from '../../../../../libs/env/src/index';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { envSalesDev } from '../../../../../libs/env/src/index';
import { env as baseEnv } from './env';

export const env: EnvironmentSalesAgentShared = merge({}, envSalesDev, baseEnv, {
  agentAuthBaseUrl: 'https://qa-agencyauthv2.homesitep2.com/v2/agencyauth',
  // internal url (using dns [qa-agencysolutions] has cert issue, so was told to use this ip instead)
  resetAgentPasswordLink: 'http://10.30.13.102/ForgotPassword.aspx',
  emailQuotesApiUrl: 'https://agentemailapi.partner-dev.homesitep2.com/', // 'https://78kw1kcv45.execute-api.us-east-1.amazonaws.com/dev/',
} as EnvironmentSalesAgentShared);
